<template>
	<el-container id="container" style="min-height: 2629px;" direction="vertical">
		<div class="block">
			<img src="../../../assets/image/background.png" alt="" style="width: 1920px; height: 480px;">
			<img src="../../../assets/image/phone_computer.png" alt="" style="width: 879px; height: 416px; position: absolute; top: 64px; left: 827px;">
			<div class="text">向数字化转型进军</div>
			<div class="text1">
				累、忙、乱？<br>
				随时随地掌控公司经营状况，才能轻松当老板！
			</div>
			<div class="text2">
				根据企业发展的不同阶段，可以选择不同的版本。<br>
				PC端＋手机端，数据实时同步。
			</div>
		</div>
		<div class="block2">
			<div class="body body1">
				<div class="text3">业务版</div>
				<div class="button" @mouseenter="buttonEnter(0)" @mouseleave="buttonLeave(0)">{{buttontext0}}</div>
				<div class="line"></div>
				<div class="text4">
					包含功能：
				</div>
				<div class="text5" style="display: flex; align-items: center; position: absolute; left: 76px; top: 296px;">
					<img src="../../../assets/image/true5.png" style="width: 23px; height: 17px;">
					<div style="margin-left: 9px;">存货管理</div>
				</div>
				<div class="text5" style="display: flex; align-items: center; position: absolute; left: 76px; top: 334px;">
					<img src="../../../assets/image/true5.png" style="width: 23px; height: 17px;">
					<div style="margin-left: 9px;">往来管理</div>
				</div>
				<div class="text5" style="display: flex; align-items: center; position: absolute; left: 76px; top: 372px;">
					<img src="../../../assets/image/true5.png" style="width: 23px; height: 17px;">
					<div style="margin-left: 9px;">资金管理</div>
				</div>
				<div class="text5" style="display: flex; align-items: center; position: absolute; left: 76px; top: 410px;">
					<img src="../../../assets/image/true5.png" style="width: 23px; height: 17px;">
					<div style="margin-left: 9px;">盈亏管理</div>
				</div>
				<div class="text5" style="display: flex; align-items: center; position: absolute; left: 76px; top: 448px;">
					<img src="../../../assets/image/true5.png" style="width: 23px; height: 17px;">
					<div style="margin-left: 9px;">项目管理</div>
				</div>
				<div class="text5" style="display: flex; align-items: center; position: absolute; left: 76px; top: 486px;">
					<img src="../../../assets/image/true5.png" style="width: 23px; height: 17px;">
					<div style="margin-left: 9px;">资产管理</div>
				</div>
			</div>
			
			<div class="body body2">
				<div class="text3">标准版</div>
				<div class="button" @mouseenter="buttonEnter(1)" @mouseleave="buttonLeave(1)">{{buttontext1}}</div>
				<div class="line"></div>
				<div class="text4">
					包含功能：
				</div>
				<div class="text5" style="display: flex; align-items: center; position: absolute; left: 76px; top: 296px;">
					<img src="../../../assets/image/true5.png" style="width: 23px; height: 17px;">
					<div style="margin-left: 9px;">业务版所有功能</div>
					<img src="../../../assets/image/hongqi.png" style="width: 26px; height: 26px; margin-left: 9px;">
				</div>
				<div class="text5" style="display: flex; align-items: center; position: absolute; left: 76px; top: 334px;">
					<img src="../../../assets/image/true5.png" style="width: 23px; height: 17px;">
					<div style="margin-left: 9px;">销售订单管理</div>
				</div>
				<div class="text5" style="display: flex; align-items: center; position: absolute; left: 76px; top: 372px;">
					<img src="../../../assets/image/true5.png" style="width: 23px; height: 17px;">
					<div style="margin-left: 9px;">采购订单管理</div>
				</div>
				<div class="text5" style="display: flex; align-items: center; position: absolute; left: 76px; top: 410px;">
					<img src="../../../assets/image/true5.png" style="width: 23px; height: 17px;">
					<div style="margin-left: 9px;">生产管理</div>
				</div>
			</div>
			
			<div class="body body3">
				<div class="text3">专业版</div>
				<div class="button" @mouseenter="buttonEnter(2)" @mouseleave="buttonLeave(2)">{{buttontext2}}</div>
				<div class="line"></div>
				<div class="text4">
					包含功能：
				</div>
				<div class="text5" style="display: flex; align-items: center; position: absolute; left: 76px; top: 296px;">
					<img src="../../../assets/image/true5.png" style="width: 23px; height: 17px;">
					<div style="margin-left: 9px;">专业财务处理</div>
				</div>
				<div class="text5" style="display: flex; align-items: center; position: absolute; left: 76px; top: 334px;">
					<img src="../../../assets/image/true5.png" style="width: 23px; height: 17px;">
					<div style="margin-left: 9px;">多组织架构</div>
				</div>
				<div class="text5" style="display: flex; align-items: center; position: absolute; left: 76px; top: 372px;">
					<img src="../../../assets/image/true5.png" style="width: 23px; height: 17px;">
					<div style="margin-left: 9px;">多年度数据处理</div>
				</div>
				<div class="text5" style="display: flex; align-items: center; position: absolute; left: 76px; top: 410px;">
					<img src="../../../assets/image/true5.png" style="width: 23px; height: 17px;">
					<div style="margin-left: 9px;">多核算项目</div>
				</div>
			</div>
			
			<div class="body body4">
				<div class="text3">旗舰版</div>
				<div class="button" @mouseenter="buttonEnter(3)" @mouseleave="buttonLeave(3)">{{buttontext3}}</div>
				<div class="line"></div>
				<div class="text4">
					包含功能：
				</div>
				<div class="text5" style="display: flex; align-items: center; position: absolute; left: 76px; top: 296px;">
					<img src="../../../assets/image/true5.png" style="width: 23px; height: 17px;">
					<div style="margin-left: 9px;">标准版所有功能</div>
					<img src="../../../assets/image/hongqi.png" style="width: 26px; height: 26px; margin-left: 9px;">
				</div>
				<div class="text5" style="display: flex; align-items: center; position: absolute; left: 76px; top: 334px;">
					<img src="../../../assets/image/true5.png" style="width: 23px; height: 17px;">
					<div style="margin-left: 9px;">专业版所有功能</div>
					<img src="../../../assets/image/hongqi.png" style="width: 26px; height: 26px; margin-left: 9px;">
				</div>
				<div class="text5" style="display: flex; align-items: center; position: absolute; left: 76px; top: 372px;">
					<img src="../../../assets/image/true5.png" style="width: 23px; height: 17px;">
					<div style="margin-left: 9px;">自动生成凭证</div>
				</div>
				<div class="text5" style="display: flex; align-items: flex-start; position: absolute; left: 76px; top: 410px;">
					<img src="../../../assets/image/true5.png" style="width: 23px; height: 17px; margin-top: 10px;">
					<div style="margin-left: 9px;">OA费用报销与流程<br>审批</div>
				</div>
				<div class="text5" style="display: flex; align-items: center; position: absolute; left: 76px; top: 486px;">
					<img src="../../../assets/image/true5.png" style="width: 23px; height: 17px;">
					<div style="margin-left: 9px;">业务订单在线审批</div>
				</div>
			</div>
		</div>
		
		<div class="block3">
			<img src="../../../assets/image/miniprogrambg.png" alt="" style="width: 1920px; height: 628px;">
			<img src="../../../assets/image/suishisuidi.png" alt="" style="width: 317px; height: 195px; position: absolute; top: 133px; left: 1372px;">
			<div class="text6">
				微信小程序
			</div>
			<div class="text7">
				不受时间和空间的限制，随时随地可以办公。
			</div>
			<div class="text8" style="display: flex; align-items: flex-start;">
				<div style="width: 10px; height: 10px; background: #FFFFFF; border-radius: 5px; margin-top: 10px;"></div>
				<div style="margin-left: 9px;">老板：通过微信小程序，随时掌控公司经营盈亏、资金收支、往来款、库存等经营状况，<br> 解放老板、让管理更简单！ 。</div>
			</div>
			<div class="text9" style="display: flex; align-items: flex-start;">
				<div style="width: 10px; height: 10px; background: #FFFFFF; border-radius: 5px; margin-top: 10px;"></div>
				<div style="margin-left: 9px;">员工：移动办公，可以直接通过手机端进行费用报销、订单处理（销售订单和采购订单）。 </div>
			</div>
			<div style="position: absolute; top: 379px; left: 210px;">
				<img src="../../../assets/image/miniprogramcode.png" alt="" style="width: 160px; height: 160px;">
			</div>
			<div class="text9" style="position: absolute; top: 486px; left: 391px;">
				微信扫码体验
			</div>
		</div>
		
		<div class="block4">
			<div class="text10">
				不同版本的功能对比
			</div>
			<div class="tableHeader" id="tableHeader" :class="{'targetFixed':ifFixed}">
				<div class="text11">产品功能</div>
				<div style="width: 1px; height: 117px; background: #858585;"></div>
				<div class="text11" style="width: 842px;">功能描述</div>
				<div style="width: 1px; height: 117px; background: #858585;"></div>
				<div style="width: 164px; display: flex; flex-flow: column; justify-content: flex-end; align-items: center;">
					<div style="position: absolute; top: 28px;font-size: 24px; font-family: AlibabaPuHuiTi_2_75_SemiBold; font-weight: normal; color: #000000;">业务版</div>
					<div class="button" @mouseenter="buttonEnter(4)" @mouseleave="buttonLeave(4)">{{buttontext4}}</div>
				</div>
				<div style="width: 1px; height: 117px; background: #858585;"></div>
				<div style="width: 164px; display: flex; flex-flow: column; justify-content: flex-end; align-items: center;">
					<div style="position: absolute; top: 28px;font-size: 24px; font-family: AlibabaPuHuiTi_2_75_SemiBold; font-weight: normal; color: #000000;">标准版</div>
					<div class="button" @mouseenter="buttonEnter(5)" @mouseleave="buttonLeave(5)">{{buttontext5}}</div>
				</div>
				<div style="width: 1px; height: 117px; background: #858585;"></div>
				<div style="width: 164px; display: flex; flex-flow: column; justify-content: flex-end; align-items: center;">
					<div style="position: absolute; top: 28px;font-size: 24px; font-family: AlibabaPuHuiTi_2_75_SemiBold; font-weight: normal; color: #000000;">专业版</div>
					<div class="button" @mouseenter="buttonEnter(6)" @mouseleave="buttonLeave(6)">{{buttontext6}}</div>
				</div>
				<div style="width: 1px; height: 117px; background: #858585;"></div>
				<div style="width: 164px; display: flex; flex-flow: column; justify-content: flex-end; align-items: center;">
					<div style="position: absolute; top: 28px;font-size: 24px; font-family: AlibabaPuHuiTi_2_75_SemiBold; font-weight: normal; color: #000000;">旗舰版</div>
					<div class="button" @mouseenter="buttonEnter(7)" @mouseleave="buttonLeave(7)">{{buttontext7}}</div>
				</div>
			</div>
			
			<div class="table1">
				<div class="tabletop">
					财务会计
					<img src="../../../assets/image/up.png" style="width: 25px; height: 25px; margin-left: 9px;" v-if="flag1" @click="flagchange(1,false)">
					<img src="../../../assets/image/down.png" style="width: 25px; height: 25px; margin-left: 9px;" v-else  @click="flagchange(1,true)">
				</div>
				<div v-if="flag1">
					<div style="width: 1500px; height: 1px; background: #858585;"></div>
					<div class="tabletext">	
						<div class="text11" style="height: 107px;">总账</div>
						<div style="width: 1px; height: 107px; background: #858585;"></div>
						<div class="text12" style="width: 842px; height: 107px;">
							<div style="margin-left: 51px; line-height: 30px;">1、多组织架构；2、多年度数据处理；<br> 3、多核算项目；4、多币别核算。</div>
						</div>
						<div style="width: 1px; height: 107px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/false3.png" style="width: 13px; height: 22px;">
						</div>
						<div style="width: 1px; height: 107px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/false3.png" style="width: 13px; height: 22px">
						</div>
						<div style="width: 1px; height: 107px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/true6.png" style="width: 18px; height: 19px;">
						</div>
						<div style="width: 1px; height: 107px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/true6.png" style="width: 18px; height: 19px;">
						</div>
					</div>
					<div style="width: 1500px; height: 1px; background: #858585;"></div>
					<div class="tabletext">	
						<div class="text11" style="height: 140px;">财务报表</div>
						<div style="width: 1px; height: 140px; background: #858585;"></div>
						<div class="text12" style="width: 842px; height: 140px;">
							<div style="margin-left: 51px; line-height: 30px;">1、资产负债表；2、利润表；<br> 3、现金流量表；4、自定义分析报表。<br>
							<b style="color: #FF0000; font-weight: normal;">*支持报表项目反查。</b></div>
						</div>
						<div style="width: 1px; height: 140px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/false3.png" style="width: 13px; height: 22px;">
						</div>
						<div style="width: 1px; height: 140px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/false3.png" style="width: 13px; height: 22px">
						</div>
						<div style="width: 1px; height: 140px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/true6.png" style="width: 18px; height: 19px;">
						</div>
						<div style="width: 1px; height: 140px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/true6.png" style="width: 18px; height: 19px;">
						</div>
					</div>
					<div style="width: 1500px; height: 1px; background: #858585;"></div>
					<div class="tabletext">	
						<div class="text11" style="height: 107px;">应收款</div>
						<div style="width: 1px; height: 107px; background: #858585;"></div>
						<div class="text12" style="width: 842px; height: 107px;">
							<div style="margin-left: 51px; line-height: 30px;">1、销售商品或劳务产生的应收、已收、未收款项明细、汇总数据；<br> 2、自动产生对账单。</div>
						</div>
						<div style="width: 1px; height: 107px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/true6.png" style="width: 18px; height: 19px;">
						</div>
						<div style="width: 1px; height: 107px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/true6.png" style="width: 18px; height: 19px;">
						</div>
						<div style="width: 1px; height: 107px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/false3.png" style="width: 13px; height: 22px">
						</div>
						<div style="width: 1px; height: 107px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/true6.png" style="width: 18px; height: 19px;">
						</div>
					</div>
					<div style="width: 1500px; height: 1px; background: #858585;"></div>
					<div class="tabletext">	
						<div class="text11" style="height: 107px;">应付款</div>
						<div style="width: 1px; height: 107px; background: #858585;"></div>
						<div class="text12" style="width: 842px; height: 107px;">
							<div style="margin-left: 51px; line-height: 30px;">1、采购商品产生的应付、已付、未付款项明细、汇总数据；<br> 2、自动产生对账单。</div>
						</div>
						<div style="width: 1px; height: 107px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/true6.png" style="width: 18px; height: 19px;">
						</div>
						<div style="width: 1px; height: 107px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/true6.png" style="width: 18px; height: 19px;">
						</div>
						<div style="width: 1px; height: 107px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/false3.png" style="width: 13px; height: 22px">
						</div>
						<div style="width: 1px; height: 107px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/true6.png" style="width: 18px; height: 19px;">
						</div>
					</div>
					<div style="width: 1500px; height: 1px; background: #858585;"></div>
					<div class="tabletext">	
						<div class="text11" style="height: 78px;">名茂捷表 <img src="../../../assets/image/hongqi.png" style="width: 26px; height: 26px;margin-left: 7px;"></div>
						<div style="width: 1px; height: 78px; background: #858585;"></div>
						<div class="text12" style="width: 842px; height: 78px;">
							<div style="margin-left: 51px; line-height: 30px;">让数据分析更简单、直观，对数据进行自由组合和多维度分析。</div>
						</div>
						<div style="width: 1px; height: 78px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/true6.png" style="width: 18px; height: 19px;">
						</div>
						<div style="width: 1px; height: 78px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/true6.png" style="width: 18px; height: 19px;">
						</div>
						<div style="width: 1px; height: 78px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/true6.png" style="width: 18px; height: 19px;">
						</div>
						<div style="width: 1px; height: 78px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/true6.png" style="width: 18px; height: 19px;">
						</div>
					</div>
					<div style="width: 1500px; height: 1px; background: #858585;"></div>
					<div class="tabletext">	
						<div class="text11" style="height: 78px;">自动凭证 <img src="../../../assets/image/hongqi.png" style="width: 26px; height: 26px;margin-left: 7px;"></div>
						<div style="width: 1px; height: 78px; background: #858585;"></div>
						<div class="text12" style="width: 842px; height: 78px;">
							<div style="margin-left: 51px; line-height: 30px;">无须自定义凭证模版，就能将业务单据完全自动转化为记账凭证。</div>
						</div>
						<div style="width: 1px; height: 78px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/false3.png" style="width: 13px; height: 22px;">
						</div>
						<div style="width: 1px; height: 78px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/false3.png" style="width: 13px; height: 22px;">
						</div>
						<div style="width: 1px; height: 78px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/false3.png" style="width: 13px; height: 22px;">
						</div>
						<div style="width: 1px; height: 78px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/true6.png" style="width: 18px; height: 19px;">
						</div>
					</div>
				</div>
				<div style="width: 1500px; height: 2px; background: #858585;"></div>
			</div>
			
			<div class="table1" :style="flag1==true ? 'margin-top: 626px' : 'margin-top: 0px' ">
				<div class="tabletop">
					管理会计
					<img src="../../../assets/image/up.png" style="width: 25px; height: 25px; margin-left: 9px;" v-if="flag2" @click="flagchange(2,false)">
					<img src="../../../assets/image/down.png" style="width: 25px; height: 25px; margin-left: 9px;" v-else  @click="flagchange(2,true)">
				</div>
				<div v-if="flag2">
					<div style="width: 1500px; height: 1px; background: #858585;"></div>
					<div class="tabletext">	
						<div class="text11" style="height: 107px;">资金管理</div>
						<div style="width: 1px; height: 107px; background: #858585;"></div>
						<div class="text12" style="width: 842px; height: 107px;">
							<div style="margin-left: 51px; line-height: 30px;">1、区分不同账户类型、收付款方式进行统计；<br> 2、实时反映各账户的增加、减少、余额变动情况。</div>
						</div>
						<div style="width: 1px; height: 107px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/true6.png" style="width: 18px; height: 19px;">
						</div>
						<div style="width: 1px; height: 107px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/true6.png" style="width: 18px; height: 19px;">
						</div>
						<div style="width: 1px; height: 107px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/false3.png" style="width: 13px; height: 22px">
						</div>
						<div style="width: 1px; height: 107px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/true6.png" style="width: 18px; height: 19px;">
						</div>
					</div>
					
					<div style="width: 1500px; height: 1px; background: #858585;"></div>
					<div class="tabletext">	
						<div class="text11" style="height: 107px;">固定资产</div>
						<div style="width: 1px; height: 107px; background: #858585;"></div>
						<div class="text12" style="width: 842px; height: 107px;">
							<div style="margin-left: 51px; line-height: 30px;">1、固定资产增加、减少、调拨、盘盈、盘亏；<br> 2、固定资产自动计提折旧。</div>
						</div>
						<div style="width: 1px; height: 107px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/true6.png" style="width: 18px; height: 19px;">
						</div>
						<div style="width: 1px; height: 107px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/true6.png" style="width: 18px; height: 19px;">
						</div>
						<div style="width: 1px; height: 107px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/false3.png" style="width: 13px; height: 22px">
						</div>
						<div style="width: 1px; height: 107px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/true6.png" style="width: 18px; height: 19px;">
						</div>
					</div>
					
					<div style="width: 1500px; height: 1px; background: #858585;"></div>
					<div class="tabletext">	
						<div class="text11" style="height: 78px;">盈亏管理 <img src="../../../assets/image/hongqi.png" style="width: 26px; height: 26px;margin-left: 7px;"></div>
						<div style="width: 1px; height: 140px; background: #858585;"></div>
						<div class="text12" style="width: 842px; height: 140px;">
							<div style="margin-left: 51px; line-height: 30px;">1、区分收入、费用类别进行显示、统计、分析；<br> 2、可按客户、供应商、部门、职员等维度分别显示；
							<br> 3、可实现“权责发生制”或“收付实现制”。</div>
						</div>
						<div style="width: 1px; height: 140px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/true6.png" style="width: 18px; height: 19px;">
						</div>
						<div style="width: 1px; height: 140px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/true6.png" style="width: 18px; height: 19px;">
						</div>
						<div style="width: 1px; height: 140px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/false3.png" style="width: 13px; height: 22px">
						</div>
						<div style="width: 1px; height: 140px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/true6.png" style="width: 18px; height: 19px;">
						</div>
					</div>
					
					<div style="width: 1500px; height: 1px; background: #858585;"></div>
					<div class="tabletext">	
						<div class="text11" style="height: 78px;">名茂捷表 <img src="../../../assets/image/hongqi.png" style="width: 26px; height: 26px;margin-left: 7px;"></div>
						<div style="width: 1px; height: 78px; background: #858585;"></div>
						<div class="text12" style="width: 842px; height: 78px;">
							<div style="margin-left: 51px; line-height: 30px;">让数据分析更简单、直观，对数据进行自由组合和多维度分析。</div>
						</div>
						<div style="width: 1px; height: 78px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/true6.png" style="width: 18px; height: 19px;">
						</div>
						<div style="width: 1px; height: 78px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/true6.png" style="width: 18px; height: 19px;">
						</div>
						<div style="width: 1px; height: 78px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/true6.png" style="width: 18px; height: 19px;">
						</div>
						<div style="width: 1px; height: 78px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/true6.png" style="width: 18px; height: 19px;">
						</div>
					</div>
					
					<div style="width: 1500px; height: 1px; background: #858585;"></div>
					<div class="tabletext">	
						<div class="text11" style="height: 107px;">管理报表<img src="../../../assets/image/hongqi.png" style="width: 26px; height: 26px;margin-left: 7px;"></div>
						<div style="width: 1px; height: 107px; background: #858585;"></div>
						<div class="text12" style="width: 842px; height: 107px;">
							<div style="margin-left: 51px; line-height: 30px;">1、经营盈亏表；2、现金收支表。<br> 
							<b style="color: #FF0000; font-weight: normal;">*支持报表项目反查。</b></div>
						</div>
						<div style="width: 1px; height: 107px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/true6.png" style="width: 18px; height: 19px;">
						</div>
						<div style="width: 1px; height: 107px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/true6.png" style="width: 18px; height: 19px;">
						</div>
						<div style="width: 1px; height: 107px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/false3.png" style="width: 13px; height: 22px">
						</div>
						<div style="width: 1px; height: 107px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/true6.png" style="width: 18px; height: 19px;">
						</div>
					</div>
					
					<div style="width: 1500px; height: 1px; background: #858585;"></div>
					<div class="tabletext">	
						<div class="text11" style="height: 107px; text-align: center;">自定义项<br>目管理<img src="../../../assets/image/hongqi.png" style="width: 26px; height: 26px;margin-left: 7px;"></div>
						<div style="width: 1px; height: 107px; background: #858585;"></div>
						<div class="text12" style="width: 842px; height: 107px;">
							<div style="margin-left: 51px; line-height: 30px;">1、可自定义成本对象，进行费用归集分配，比如：培训项目等；
							<br> 2、可自定义核算项目，进行项目盈亏核算，比如：工程项目等。</div>
						</div>
						<div style="width: 1px; height: 107px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/true6.png" style="width: 18px; height: 19px;">
						</div>
						<div style="width: 1px; height: 107px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/true6.png" style="width: 18px; height: 19px;">
						</div>
						<div style="width: 1px; height: 107px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/false3.png" style="width: 13px; height: 22px">
						</div>
						<div style="width: 1px; height: 107px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/true6.png" style="width: 18px; height: 19px;">
						</div>
					</div>
				</div>
				<div style="width: 1500px; height: 2px; background: #858585;"></div>
			</div>
			
			<div class="table1" :style="flag2==true ? 'margin-top: 653px' : 'margin-top: 0px' ">
				<div class="tabletop">
					供应链管理
					<img src="../../../assets/image/up.png" style="width: 25px; height: 25px; margin-left: 9px;" v-if="flag3" @click="flagchange(3,false)">
					<img src="../../../assets/image/down.png" style="width: 25px; height: 25px; margin-left: 9px;" v-else  @click="flagchange(3,true)">
				</div>
				<div v-if="flag3">
					<div style="width: 1500px; height: 1px; background: #858585;"></div>
					<div class="tabletext">	
						<div class="text11" style="height: 78px;">仓存管理</div>
						<div style="width: 1px; height: 140px; background: #858585;"></div>
						<div class="text12" style="width: 842px; height: 140px;">
							<div style="margin-left: 51px; line-height: 30px;">1、物料入库、出库、调拨、盘盈、盘亏、结存核算；
							<br> 2、商品入库、销售、调拨、盘盈、盘亏、结存、成本、毛利核算；
							<br> 3、自动计税、价税分离、即时显示当前库存，超安全存量提醒等。</div>
						</div>
						<div style="width: 1px; height: 140px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/true6.png" style="width: 18px; height: 19px;">
						</div>
						<div style="width: 1px; height: 140px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/true6.png" style="width: 18px; height: 19px;">
						</div>
						<div style="width: 1px; height: 140px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/false3.png" style="width: 13px; height: 22px;">
						</div>
						<div style="width: 1px; height: 140px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/true6.png" style="width: 18px; height: 19px;">
						</div>
					</div>
					
					<div style="width: 1500px; height: 1px; background: #858585;"></div>
					<div class="tabletext">	
						<div class="text11" style="height: 78px;">存货核算</div>
						<div style="width: 1px; height: 78px; background: #858585;"></div>
						<div class="text12" style="width: 842px; height: 78px;">
							<div style="margin-left: 51px; line-height: 30px;">计算发出存货成本，结转销售成本。</div>
						</div>
						<div style="width: 1px; height: 78px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/true6.png" style="width: 18px; height: 19px;">
						</div>
						<div style="width: 1px; height: 78px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/true6.png" style="width: 18px; height: 19px;">
						</div>
						<div style="width: 1px; height: 78px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/false3.png" style="width: 13px; height: 22px;">
						</div>
						<div style="width: 1px; height: 78px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/true6.png" style="width: 18px; height: 19px;">
						</div>
					</div>
					
					<div style="width: 1500px; height: 1px; background: #858585;"></div>
					<div class="tabletext">	
						<div class="text11" style="height: 78px;">采购管理</div>
						<div style="width: 1px; height: 140px; background: #858585;"></div>
						<div class="text12" style="width: 842px; height: 140px;">
							<div style="margin-left: 51px; line-height: 30px;">1、采购订单录入与查询；2、采购合同到期提醒；
							<br> 3、采购订单追踪，实时查询供应商采购、收货情况；
							<br> 4、采购合同付款进度情况。</div>
						</div>
						<div style="width: 1px; height: 140px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/false3.png" style="width: 13px; height: 22px;">
						</div>
						<div style="width: 1px; height: 140px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/true6.png" style="width: 18px; height: 19px;">
						</div>
						<div style="width: 1px; height: 140px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/false3.png" style="width: 13px; height: 22px;">
						</div>
						<div style="width: 1px; height: 140px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/true6.png" style="width: 18px; height: 19px;">
						</div>
					</div>
					
					<div style="width: 1500px; height: 1px; background: #858585;"></div>
					<div class="tabletext">	
						<div class="text11" style="height: 78px;">销售管理</div>
						<div style="width: 1px; height: 140px; background: #858585;"></div>
						<div class="text12" style="width: 842px; height: 140px;">
							<div style="margin-left: 51px; line-height: 30px;">1、销售订单录入与查询；2、销售合同到期提醒；
							<br> 3、销售订单追踪，实时查询客户订购、发货情况；
							<br> 4、销售合同收款进度情况。</div>
						</div>
						<div style="width: 1px; height: 140px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/false3.png" style="width: 13px; height: 22px;">
						</div>
						<div style="width: 1px; height: 140px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/true6.png" style="width: 18px; height: 19px;">
						</div>
						<div style="width: 1px; height: 140px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/false3.png" style="width: 13px; height: 22px;">
						</div>
						<div style="width: 1px; height: 140px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/true6.png" style="width: 18px; height: 19px;">
						</div>
					</div>
					
					<div style="width: 1500px; height: 1px; background: #858585;"></div>
					<div class="tabletext">	
						<div class="text11" style="height: 78px;">生产管理</div>
						<div style="width: 1px; height: 140px; background: #858585;"></div>
						<div class="text12" style="width: 842px; height: 140px;">
							<div style="margin-left: 51px; line-height: 30px;">1、根据BOM表计算物料用量与采购量；
							<br>2、实时反映生产制令“标准用量”与“实际用量”差异；
							<br>3、根据成本对象，准确归集和分配</div>
						</div>
						<div style="width: 1px; height: 140px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/false3.png" style="width: 13px; height: 22px;">
						</div>
						<div style="width: 1px; height: 140px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/true6.png" style="width: 18px; height: 19px;">
						</div>
						<div style="width: 1px; height: 140px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/false3.png" style="width: 13px; height: 22px;">
						</div>
						<div style="width: 1px; height: 140px; background: #858585;"></div>
						<div style="width: 164px; display: flex; justify-content: center;">
							<img src="../../../assets/image/true6.png" style="width: 18px; height: 19px;">
						</div>
					</div>
					
					
				</div>
				<div style="width: 1500px; height: 2px; background: #858585;"></div>
			</div>
			
			<div class="table1" :style="flag3==true ? 'margin-top: 645px' : 'margin-top: 0px' ">
				<div class="tabletop">
					移动办公
					<img src="../../../assets/image/up.png" style="width: 25px; height: 25px; margin-left: 9px;" v-if="flag4" @click="flagchange(4,false)">
					<img src="../../../assets/image/down.png" style="width: 25px; height: 25px; margin-left: 9px;" v-else  @click="flagchange(4,true)">
				</div>
				<div v-if="flag4">
					<div style="width: 1500px; height: 1px; background: #858585;"></div>
					<div class="tabletext">	
						<div class="text11" style="height: 78px;">微信小程序</div>
						<div style="width: 1px; height: 141px; background: #858585;"></div>
						<div class="text12" style="width: 841px; height: 140px;">
							<div style="line-height: 70px;">
								<div style="margin-left: 51px;">
									1、经营盈亏表；2、现金收支表；
								</div>
								<div style="width: 749px; height: 1px; background: #858585;"></div>
								<div style="margin-left: 51px;">
									3、费用报销与审批；4、订单在线审批。
								</div>
							</div>
						</div>
						<div style="width: 1px; height: 141px; background: #858585;"></div>
						
						<div style="width: 146px; display: flex; justify-content: center;">
							<div>
								<div style="width: 146px; height: 70px; display: flex; justify-content: center; align-items: center;">
									<img src="../../../assets/image/true6.png" style="width: 18px; height: 19px;">
								</div>
								<div style="width: 146px; height: 1px; background: #858585;"></div>
								<div style="width: 146px; height: 70px; display: flex; justify-content: center; align-items: center;">
									<img src="../../../assets/image/false3.png" style="width: 13px; height: 22px;">
								</div>
							</div>
						</div>
						<div style="width: 1px; height: 141px; background: #858585;"></div>
						<div  style="width: 146px; display: flex; justify-content: center;">
							<div>
								<div style="width: 146px; height: 70px; display: flex; justify-content: center; align-items: center;">
									<img src="../../../assets/image/true6.png" style="width: 18px; height: 19px;">
								</div>
								<div style="width: 146px; height: 1px; background: #858585;"></div>
								<div style="width: 146px; height: 70px; display: flex; justify-content: center; align-items: center;">
									<img src="../../../assets/image/true6.png" style="width: 18px; height: 19px;">
								</div>
							</div>
						</div>
						<div style="width: 1px; height: 141px; background: #858585;"></div>
						<div  style="width: 146px; display: flex; justify-content: center;">
							<div>
								<div style="width: 146px; height: 70px; display: flex; justify-content: center; align-items: center;">
									<img src="../../../assets/image/true6.png" style="width: 18px; height: 19px;">
								</div>
								<div style="width: 146px; height: 1px; background: #858585;"></div>
								<div style="width: 146px; height: 70px; display: flex; justify-content: center; align-items: center;">
									<img src="../../../assets/image/false3.png" style="width: 13px; height: 22px;">
								</div>
							</div>
						</div>
						<div style="width: 1px; height: 141px; background: #858585;"></div>
						<div style="width: 146px; display: flex; justify-content: center;">
							<div>
								<div style="width: 146px; height: 70px; display: flex; justify-content: center; align-items: center;">
									<img src="../../../assets/image/true6.png" style="width: 18px; height: 19px;">
								</div>
								<div style="width: 146px; height: 1px; background: #858585;"></div>
								<div style="width: 146px; height: 70px; display: flex; justify-content: center; align-items: center;">
									<img src="../../../assets/image/true6.png" style="width: 18px; height: 19px;">
								</div>
							</div>
						</div>
						
					</div>
					
				</div>
				<div style="width: 1500px; height: 2px; background: #858585;"></div>
			</div>
		</div>
	</el-container>
</template>

<script>
  export default {
    name: "product",
		data() {
			return {
				buttontext0: "免费咨询",
				buttontext1: "免费咨询",
				buttontext2: "免费咨询",
				buttontext3: "免费咨询",
				buttontext4: "免费咨询",
				buttontext5: "免费咨询",
				buttontext6: "免费咨询",
				buttontext7: "免费咨询",
				height: 3000,
				flag1: false,
				flag2: false,
				flag3: false,
				flag4: false,
				tableHeight: 2729,
				ifFixed: false,
				// 目标元素与整个文档顶部间的距离
				targetTop: 2086
			}
		},
		methods:{
			buttonEnter(index) {
				this.$set(this, `buttontext${index}`, "合作专线：13807323918（孙老师）");
			},
			buttonLeave(index) {
				this.$set(this, `buttontext${index}`, "免费咨询");
			},
			flagchange(index,flag) {
				this.$set(this, `flag${index}`, flag);
				var num = 2629;
				if (this.flag1 == true) {
					num += 626;
				}
				if (this.flag2 == true) {
					num += 653;
				}
				if (this.flag3 == true) {
					num += 645;
				}
				if (this.flag4 == true) {
					num += 146;
				}
				let element = document.getElementById("container");
				element.style.height = num+'px';
				console.log(element)
				if (this.flag4 || this.flag3 || this.flag2  || this.flag1) {
					var element2 = document.getElementById("tableHeader");
					element2.style.backgroundColor = '#FFFAF7';
					element2.style.boxShadow = '12px 10px 21px 0px rgba(63,63,63,0.26)';
				} else {
					var element2 = document.getElementById("tableHeader");
					element2.style.backgroundColor = '#FFF';
					element2.style.boxShadow = 'none';
				}
			},
			scrolling() {
				// 获取当前滚动条向下滚动的距离
				let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
				if (scrollTop > this.targetTop) {
					this.ifFixed = true;
				} else {
					this.ifFixed = false;
				}
			},
		},
		mounted() {
			// 监听页面滚动事件
			window.addEventListener("scroll", this.scrolling)
		},
  }
</script>

<style lang="less" scoped>
	.el-container {
		width: 1920px;
		
		.block {
			width: 1920px;
			height: 480px;
			position: absolute;
			
			.text {
				font-size: 50px;
				font-family: AlibabaPuHuiTi_2_75_SemiBold;
				font-weight: normal;
				color: #FFFFFF;
				position: absolute;
				top: 103px;
				left: 210px;
			}
			
			.text1 {
				font-size: 22px;
				font-family: AlibabaPuHuiTi_2_45_Light;
				font-weight: normal;
				color: #FFFFFF;
				line-height: 36px;
				position: absolute;
				top: 176px;
				left: 210px;
			}
			
			.text2 {
				font-size: 22px;
				font-family: AlibabaPuHuiTi_2_45_Light;
				font-weight: normal;
				color: #FFFFFF;
				line-height: 36px;
				position: absolute;
				top: 293px;
				left: 210px;
			}
		}
		
		.block2 {
			width: 1920px;
			height: 778px;
			background: #F0F3FA;
			position: absolute;
			top: 580px;
			
			.text3 {
				font-size: 36px;
				font-family: AlibabaPuHuiTi_2_55_Regular;
				font-weight: normal;
				color: #000000;
				line-height: 36px;
				position: absolute;
				top: 73px;
				left: 122px;
			}
			
			.button {
				width: 150px;
				height: 44px;
				display: flex;
				align-items: center;
				justify-content: center;
				background: #FF0000;
				font-size: 16px;
				font-family: AlibabaPuHuiTi_2_45_Light;
				font-weight: normal;
				color: #FFFFFF;
				border-radius: 30px;
				position: absolute;
				left: 99px;
				top: 125px;
			}
			
			.button:hover {
				width: 288px;
				height: 44px;
				background: linear-gradient(0deg, #D50000 0%, #FF5400 100%);
				border-radius: 22px;
				font-size: 16px;
				font-family: AlibabaPuHuiTi_2_55_Regular;
				font-weight: normal;
				color: #FFFFFF;
				position: absolute;
				left: 30px;
				top: 125px;
			}
			
			.line {
				width: 288px;
				height: 0px;
				background: #FFFFFF;
				border-top: 1px solid #C7CFD8;
				position: absolute;
				top: 199px;
				left: 30px;
			}
			
			.text4 {
				font-size: 22px;
				font-family: AlibabaPuHuiTi_2_45_Light;
				font-weight: normal;
				color: #888888;
				position: absolute;
				top: 243px;
				left: 60px;
			}
			
			.text5 {
				font-size: 22px;
				font-family: AlibabaPuHuiTi_2_45_Light;
				font-weight: normal;
				color: #000000;
				line-height: 36px;
			}
			
			.body {
				width: 348px;
				height: 590px;
				background: #FFFFFF;
				border: 1px solid #C7CFD8;
				border-radius: 20px;
			}
			
			.body1 {
				position: absolute;
				left: 210px;
				top: 95px;
			}
			
			.body2 {
				position: absolute;
				left: 594px;
				top: 95px;
			}
			
			.body3 {
				position: absolute;
				left: 978px;
				top: 95px;
			}
			
			.body4 {
				position: absolute;
				left: 1362px;
				top: 95px;
			}
		}
		
		.block3 {
			width: 1920px;
			height: 628px;
			position: absolute;
			top: 1358px;
			
			.text6 {
				font-size: 48px;
				font-family: AlibabaPuHuiTi_2_75_SemiBold;
				font-weight: normal;
				color: #FFFFFF;
				position: absolute;
				top: 115px;
				left: 210px;
			}
			
			.text7 {
				font-size: 22px;
				font-family: AlibabaPuHuiTi_2_45_Light;
				font-weight: normal;
				color: #FFFFFF;
				line-height: 36px;
				position: absolute;
				top: 183px;
				left: 210px;
			}
			
			.text8 {
				font-size: 22px;
				font-family: AlibabaPuHuiTi_2_45_Light;
				font-weight: normal;
				color: #FFFFFF;
				line-height: 36px;
				position: absolute;
				top: 244px;
				left: 210px;
			}
			
			.text9 {
				font-size: 22px;
				font-family: AlibabaPuHuiTi_2_45_Light;
				font-weight: normal;
				color: #FFFFFF;
				line-height: 36px;
				position: absolute;
				top: 318px;
				left: 210px;
			}
		}
		
		.block4 {
			width: 1920px;
			height: auto;
			min-height: 732px;
			position: absolute;
			top: 1986px;
			
			.text10 {
				font-size: 50px;
				font-family: AlibabaPuHuiTi_2_75_SemiBold;
				font-weight: normal;
				color: #000000;
				position: absolute;
				top: 90px;
				left: 746px;
			}
			
			.targetFixed {
				position: fixed !important;
				top: 99px !important;
			}
			
			.tableHeader {
				width: 1500px;
				height: 115px;
				border-width: 2px;
				border-color: #858585;
				border-style: solid;
				border-left: none;
				background-color: #fff;
				box-shadow: none;
				border-right: none;
				position: absolute; 
				top: 198px; 
				left: 210px;
				display: flex;
				align-items: center;
				justify-content: center;
				
				.text11 {
					height: 115px;
					width: 182px;
					font-size: 24px;
					font-family: AlibabaPuHuiTi_2_75_SemiBold;
					font-weight: normal;
					color: #000000;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				
				.button {
					width: 108px;
					height: 27px;
					display: flex;
					align-items: center;
					justify-content: center;
					background: #FF0000;
					font-size: 14px;
					font-family: AlibabaPuHuiTi_2_45_Light;
					font-weight: normal;
					color: #FFFFFF;
					border-radius: 14px;
					position: absolute;
					top: 65px;
				}
				
				.button:hover {
					width: 180px;
					height: 64px;
					display: flex;
					align-items: center;
					justify-content: center;
					text-align: center;
					background: linear-gradient(0deg, #D50000 0%, #FF5400 100%);
					box-shadow: 4px 2px 7px 1px #DEE0E7;
					border-radius: 5px;
					font-size: 14px;
					line-height: 22px;
					font-family: AlibabaPuHuiTi_2_55_Regular;
					font-weight: normal;
					color: #FFFFFF;
					position: absolute;
					top: 65px;
				}
			}
			
			.tabletext {
				display: flex; 
				align-items: center;
				justify-content: center; 
				font-size: 20px; 
				font-family: AlibabaPuHuiTi_2_45_Light; 
				font-weight: normal;
				color: #000000;
			}
			
			.table1 {
				width: 1500px;
				height: 79px;
				margin: 315px 0 0 210px;
				
				.tabletop {
					width: 164px;
					height: 79px;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 24px;
					font-family: AlibabaPuHuiTi_2_55_Regular;
					font-weight: normal;
					color: #000000;
				}
				
				.text11 {
					width: 182px;
					font-size: 24px;
					font-family: AlibabaPuHuiTi_2_75_SemiBold;
					font-weight: normal;
					color: #000000;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				
				.text12 {
					font-size: 20px;
					font-family: AlibabaPuHuiTi_2_45_Light;
					font-weight: normal;
					color: #000000;
					display: flex;
					align-items: center;
					justify-content: flex-start;
				}
			}
		}
	}
</style>